(function () {
  'use strict';

  /* @ngdoc object
   * @name referees.newReferee
   * @description
   *
   */
  angular
    .module('neo.home.referees.newReferee', [
    ]);
}());
